<template>
  <a-form
    layout="vertical"
    :model="form"
    ref="formRef"
    name="add_org"
    :rules="validationsRules()"
    class="org-form"
    @finish="onSubmit"
  >
    <div class="row">
      <div class="col-sm-6">
        <a-form-item label="Organization Name" name="name">
          <a-input v-model:value="form.name" placeholder="Enter Name">
          </a-input>
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Address" name="address">
          <a-input v-model:value="form.address" placeholder="Enter Address">
          </a-input>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <a-form-item label="Employees" name="employee" class="w-100">
          <a-input-number
            id="inputNumber"
            style="width: 220px"
            placeholder="Enter Number of Employee"
            v-model:value="form.employee"
            :min="0"
            :max="10000"
          />
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Description" name="desc" autocomplete="off">
          <a-input v-model:value="form.desc" placeholder="Enter Description" />
        </a-form-item>
      </div>
    </div>

    <div class="text-right">
      <a-button @click="onCancel">
        Cancel
      </a-button>
      <a-button
        type="primary"
        html-type="submit"
        :loading="isLoading"
        class="ml-2"
      >
        {{ edit === true ? 'Update' : 'Create' }}
      </a-button>
    </div>
  </a-form>
</template>
<script>
import { ref } from 'vue';
import { mapActions } from 'vuex';
export default {
  emits: ['cancel', 'resetFormValues'],
  props: ['initialValues', 'edit'],
  data() {
    return {
      isLoading: false,
      Org_name: '',
      form: { ...this.initialValues },
    };
  },
  watch: {
    initialValues(value) {
      this.form = { ...value };
      this.Org_name = value.name;
    },
  },
  methods: {
    ...mapActions(['addNewOrg', 'updateSingleOrg']),
    validationsRules() {
      return {
        name: [
          {
            required: true,
            trigger: '[submit,cancel]',
            message: 'Name is required',
          },
        ],
        address: [
          {
            required: true,
            trigger: '[submit,cancel]',
            message: 'Address is required',
          },
        ],
        employee: [
          {
            required: true,
            trigger: '[submit,cancel]',
            message: 'Employees are required',
          },
        ],
        desc: [
          {
            required: true,
            trigger: '[submit,cancel]',
            message: 'Description is required',
          },
        ],
      };
    },
    onSubmit() {
      this.isLoading = true;
      const data = {
        Org_name: this.form.name,
        Number_of_emp: this.form.employee,
        Description: this.form.desc,
        Address: this.form.address,
      };
      this.edit ? this.updateOrg(data) : this.addOrg(data);
    },
    async addOrg(reqBody) {
      const response = await this.addNewOrg(reqBody);
      this.isLoading = false;
      if (response !== 'error') {
        this.onCancel();
      }
      this.$emit('resetFormValues');
    },
    async updateOrg(reqBody) {
      const response = await this.updateSingleOrg({
        id: this.Org_name,
        ...reqBody,
      });
      this.isLoading = false;
      if (response !== 'error') {
        this.onCancel();
      }
      this.$emit('resetFormValues');
    },
    onCancel() {
      this.$emit('cancel');
      this.$emit('resetFormValues');
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
}
</style>
