<template>
  <a-modal
    :visible="showDeleteOrg"
    centered
    title="Delete Organization"
    okText="Submit"
    @ok="isConfirm = true"
    @cancel="isConfirm = false"
    :okButtonProps="{ id: 'delete-org-ok-btn' }"
    :cancelButtonProps="{
      id: 'delete-org-cancel-btn',
      style: { marginLeft: 'auto' }
    }"
    id="org-deletion-modal"
  >
    <template #closeIcon>
      <close-outlined id="delete-org-close-btn" />
    </template>
    <p>
      Are you sure? you want to delete the organization.<br />
      Please enter organization name to delete.
    </p>
    <div class="d-flex flex-column">
      <a-input class="w-50" id="orgDeleteMsg" v-model:value="orgDeleteMsg" />
      <small class="text-danger" v-if="isError"
        >Please enter correct name!</small
      >
    </div>
  </a-modal>

  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
    <a-input-search
      v-model:value="searchValue"
      placeholder="Search Organization By Name"
    />
  </a-col>

  <a-table
    :columns="columns"
    :data-source="filterOrganization"
    bordered
    :scroll="{ y: '55vh', x: '900px' }"
  >
    <template #title>
      <a-typography-title :level="5">
        Organizations: &nbsp;
        <a-tag color="blue">{{ list.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'actions'">
        <div class="actions">
          <a-dropdown :trigger="['click']">
            <a-button class="ml-2">
              <template #icon>
                <MenuOutlined class="menu-icon" />
              </template>
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="createKaizenBucket(record.Org_name)">
                  <PlusOutlined />
                  Create Kaizen Bucket
                </a-menu-item>
                <a-menu-item @click="refreshTrainingVides(record.Org_name)">
                  <RedoOutlined />
                  Referesh Videos
                </a-menu-item>
                <a-menu-item @click="onEdit(record)">
                  <EditOutlined />
                  Edit
                </a-menu-item>
                <a-menu-item @click="handleDeleteOrg(record.Org_name)">
                  <DeleteOutlined />
                  Delete
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  MenuOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined
} from '@ant-design/icons-vue';
import OrgServices from 'src/services/organization';

export default {
  emits: ['onEditClick'],
  name: 'organization-component',
  inject: ['toast'],
  components: {
    MenuOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
  },

  data() {
    return {
      columns: [
        {
          title: 'Name',
          dataIndex: 'Org_name'
        },
        {
          title: 'Address',
          dataIndex: 'Address'
        },
        {
          title: 'No Of Employees',
          dataIndex: 'Number_of_emp',
          width: '15%'
        },
        {
          title: 'Description',
          dataIndex: 'Description'
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          width: '10%'
        }
      ],
      list: [],
      orgDeleteMsg: '',
      searchValue: '',
      orgToDelete: null,
      showDeleteOrg: false,
      isConfirm: null,
      isError: false
    };
  },
  created() {
    this.getOrganizations();
  },
  computed: {
    ...mapGetters(['organizations']),

    filterOrganization() {
      if (this.searchValue) {
        const inputValue = this.searchValue.toLowerCase();
        return this.list.filter(({ Org_name }) => {
          const name = Org_name?.toLowerCase();
          return name?.includes(inputValue);
        });
      } else {
        return this.list;
      }
    }
  },

  watch: {
    filterOrganization() {},
    isConfirm(value) {
      if (value === null) return;
      if (value) {
        if (this.orgDeleteMsg !== this.orgToDelete) {
          this.isError = true;
          this.isConfirm = null;
          return;
        }
        this.deleteOrg();
      } else {
        this.taskUpdateMsg = '';
        this.isError = false;
      }
      this.showDeleteOrg = false;
    },
    organizations(value) {
      if (value.length >= 1) {
        this.list = value;
      }
    }
  },
  methods: {
    ...mapActions([
      'getOrganizations',
      'addNewOrg',
      'deleteSingleOrg',
      'refreshVideos'
    ]),
    handleDeleteOrg(orgName) {
      this.orgToDelete = orgName;
      this.showDeleteOrg = true;
    },

    deleteOrg() {
      this.deleteSingleOrg(this.orgToDelete);
    },
    onEdit(rowData) {
      this.$emit('onEditClick', rowData);
    },
    async refreshTrainingVides(orgName) {
      const res = await this.refreshVideos(orgName);
      if (res === 'error') {
        this.toast.error('Error occured while refreshing videos!');
        return;
      }
      this.toast.success(`${res.body} videos refreshed!`);
    },
    async createKaizenBucket(orgName) {
      const payload = {
        organization: orgName
      };
      const [error, data] = await OrgServices.createKaizenBucket(payload);
      if (error) {
        const errMsg = error.response.data['response'];
        this.toast.info(errMsg);
        return;
      }
      this.toast.success(data.response);
    }
  }
};
</script>

<style scoped>
.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 6px;
}

.error-msg {
  color: red;
}

.error-box {
  width: 140px;
  margin-left: 20px;
}
</style>
